import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineLibraryAdd } from "react-icons/md";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";

export default function Testimonials() {
  const [open, setOpen] = useState(false);
  let initialdata = {
    metatitle: "",
    metadec: "",
    sitmaplink: "",
    canonicalutl: "",
  };

  const [Testimonialdata, setTestimonialdata] = useState(initialdata);
  const [Testimonial, setTestimonial] = useState([]);
  const [SelectCate, setSelectCate] = useState("");
  const [Edit, setEdit] = useState(null);

  const columns = [
    {
      name: "Meta Title",
      selector: (row) => row.metatitle,
    },
    {
      name: "Meta Description",
      selector: (row) => row.metadec,
    },

    {
      name: "Sitemap Link",
      selector: (row) => row.sitmaplink,
    },

    {
      name: "Canonical Url",
      selector: (row) => row.canonicalutl,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <span className="me-1 edit cursor " onClick={() => handleEdit(row)}>
            Edit
          </span>{" "}
          <span className="m-auto me-1 text-bold">|</span>
          <span className="delete cursor" onClick={() => handleDelete(row)}>
            Delete
          </span>
        </>
      ),
    },
  ];

  const handleAddCategory = () => {
    setEdit(null);
    setSelectCate();

    setOpen(true);
  };

  const handleSaveOrUpdate = async () => {
    try {
      const url = `https://api.vijayhomeservice.com/api/seo/updatedseo/${
        Edit ? Edit._id : SelectCate
      }`;
      const config = {
        url,
        method: "put",
        data: {
          metatitle: Testimonialdata.metatitle,
          metadec: Testimonialdata.metadec,
          sitmaplink: Testimonialdata.sitmaplink,
          canonicalutl: Testimonialdata.canonicalutl,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert("Updated successfully");
        setOpen(false);

        window.location.reload("");
      }
    } catch (error) {
      console.log("Error in handleSaveOrUpdate:", error);
    }
  };

  const handleAddTestimonial = async () => {
    try {
      const config = {
        url: "https://api.vijayhomeservice.com/api/seo/addseo",
        headers: { "Content-Type": "application/json" },
        method: "post",
        data: {
          metatitle: Testimonialdata.metatitle,
          metadec: Testimonialdata.metadec,
          sitmaplink: Testimonialdata.sitmaplink,
          canonicalutl: Testimonialdata.canonicalutl,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        alert("added successfully");
        setOpen(false);

        getWhyChooose();
      }
    } catch (error) {
      console.log("Error in handleSaveOrUpdate:", error);
    }
  };

  useEffect(() => {
    if (Edit) {
      setTestimonialdata({
        metatitle: Edit.metatitle || Testimonialdata.metatitle,
        metadec: Edit.metadec || Testimonialdata.metadec,
        sitmaplink: Edit.sitmaplink || Testimonialdata.sitmaplink,
        canonicalutl: Testimonialdata.canonicalutl,
      });
    }
    getWhyChooose();
  }, [Edit]);

  const getWhyChooose = async () => {
    try {
      const res = await axios.get(
        "https://api.vijayhomeservice.com/api/seo/getallseo"
      );
      setTestimonial(res.data.data);
    } catch (error) {
      console.log("Error in getcategory:", error);
    }
  };

  const handleDelete = async (row) => {
    try {
      const res = await axios.post(
        `https://api.vijayhomeservice.com/api/seo/deleteseo/${row._id}`
      );
      if (res.status === 200) {
        alert("Deleted Succesfully");
        getWhyChooose();
        window.location.reload("");
      }
    } catch (error) {
      console.log("Error in getcategory:", error);
    }
  };

  const handleEdit = (row) => {
    setEdit(row);
    setOpen(true);
  };
  const handleChange = (e) => {
    let { value, name } = e.target;
    setTestimonialdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // console.log(Testimonialdata, "Testimonialdata");

  return (
    <div className="row m-auto p-2">
      <div className="row text-center">
        <div className="col-md-3 d-flex m-auto">
          <span className="m-auto text-bold"> Meta Details </span>
          <MdOutlineLibraryAdd
            onClick={handleAddCategory}
            className="m-auto cursor"
          />
        </div>
      </div>
      <DataTable columns={columns} data={Testimonial} pagination={true} />

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Edit ? "Edit Meta Details" : "Add Meta Details"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Meta Title</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Meta Title"
              value={Testimonialdata.metatitle}
              name="metatitle"
              autoFocus
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Meta Description</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              placeholder="Meta Description"
              value={Testimonialdata.metadec}
              name="metadec"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sitemap Link</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              name="sitmaplink"
              placeholder="Sitemap Link"
              value={Testimonialdata.sitmaplink}
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Canonical Url</Form.Label>
            <Form.Control
              onChange={handleChange}
              as="textarea"
              name="canonicalutl"
              placeholder="Canonical Url"
              value={Testimonialdata.canonicalutl}
              autoFocus
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="col-md-2 me-auto"
            variant="secondary"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          {Edit ? (
            <Button
              className="col-md-2 "
              variant="primary"
              onClick={handleSaveOrUpdate}
            >
              Update
            </Button>
          ) : (
            <Button
              className="col-md-2 "
              variant="primary"
              onClick={handleAddTestimonial}
            >
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
